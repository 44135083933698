@import url(https://fonts.googleapis.com/css?family=Inter:400,600,800);

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99999;
    font-weight: 400;
    pointer-events: none;
    opacity: 0;
    transition-property: opacity;
}

.popup-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.popup-header {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 20%;
}

.popup-title {
    margin-top: 5vh;
    font-size: min(55px, 11vw);
    font-weight: 600;
}

.popup-close {
    position: absolute;
    top: 0%;
    right: 2.2%;
    cursor: pointer;
    font-size: min(48.1px, 8vw);
}

.popup-body {
    text-align: center;
}

.popup-body p {
    margin-top: 2%;
    margin-bottom: 2%;
}

.popup-body:last-child {
    margin-bottom: 4%;
}

.popup-body a {
    text-decoration: none;
    font-weight: 600;
}

/* fade-in and fade-out */

.popup.fade-in {
    pointer-events: all;
    opacity: 1;
}

.popup.fade-out {
    pointer-events: none;
    opacity: 0;
}

.popup button {
    padding: 1% 2%;
    border-radius: min(10px, 1.5vw);
    font-size: inherit;
    cursor: pointer;
}

/* specific styling */
.popup .big-margin {
    margin-top: 5% !important;
}

.popup .space-out {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popup .black {
    color: #000;
}

.popup .white {
    color: #fff;
}

.popup .red {
    color: #ff0000;
}

.popup .red-button {
    background-color: #ff0000 !important;
}

.popup .green {
    color: #00ff00;
}

.popup .green-button {
    background-color: #00ff00 !important;
}

.popup .blue {
    color: #0000ff;
}

.popup .blue-button {
    background-color: #0000ff !important;
}

.popup .bold {
    font-weight: 800;
}

.popup .shadow {
    text-shadow: 0 0 5px #000;
}